/* eslint-disable no-undef */
var app = angular.module('PouchCue')
app.controller('LoginController', [
  '$http',
  'GlobalService',
  '$scope',
  '$timeout',
  function($http, GlobalService, $scope, $timeout) {
    //$scope is only used for emit and broadcast events. use ctrl instead.

    var ctrl = this

    ctrl.firstName = ''
    ctrl.lastName = ''
    ctrl.email = ''
    ctrl.password = ''
    ctrl.retypePassword = ''

    ctrl.loginEmail = ''
    ctrl.loginPassword = ''
    ctrl.authFailed = false
    ctrl.signUpError = false
    ctrl.resetPasswordFailed = false
    ctrl.didYouMean = null
    ctrl.mailgunPassed = true
    ctrl.passwordError = false
    ctrl.redirectUrl = null

    ctrl.states = {
      default: 0,
      signIn: 1,
      signUp: 2,
      resetting: 3,
      resetSuccess: 4
    }

    ctrl.userData = {}

    ctrl.state = ctrl.states.default

    ctrl.init = function() {
      ctrl.state = ctrl.states.default
      ctrl.userData = GlobalService.getUser()
      ctrl.loginEmail = ctrl.userData.email
      if (
        ctrl.loginEmail &&
        ctrl.loginEmail !== '' &&
        ctrl.loginEmail !== null
      ) {
        ctrl.email = ctrl.loginEmail // for signup
      }
    }

    ctrl.initSignUpFromEmail = function(redirectUrl) {
      ctrl.init()
      ctrl.redirectUrl = redirectUrl
    }

    GlobalService.subscribeLoginClicked($scope, function changed() {
      ctrl.resetModalValues()
    })

    ctrl.resetModals = function() {
      GlobalService.notifyLoginClicked()
    }

    ctrl.resetModalValues = function() {
      ctrl.firstName = ''
      ctrl.lastName = ''
      ctrl.email = ''
      ctrl.password = ''
      ctrl.retypePassword = ''
      ctrl.loginEmail = ''
      ctrl.loginPassword = ''
      ctrl.setPristineAll(ctrl.signUpForm)
      ctrl.setPristineAll(ctrl.loginForm)
      ctrl.loginError = false
      ctrl.authFailed = false
      ctrl.signUpError = false
      ctrl.resetPasswordFailed = false
      ctrl.didYouMean = null
      ctrl.mailgunPassed = true
      ctrl.passwordError = false
    }

    ctrl.setPristineAll = function(form) {
      if (typeof form !== 'undefined' && form !== null) {
        angular.forEach(form, function(value) {
          if (
            typeof value === 'object' &&
            value.hasOwnProperty('$modelValue')
          ) {
            value.$setPristine()
          }
        })
        form.$setPristine()
        form.$setUntouched()
      }
    }

    ctrl.doLogin = function() {
      if (ctrl.checkRequiredFields(ctrl.loginForm) === false) {
        return
      }
      ctrl.state = ctrl.states.signIn

      var data = {
        email: ctrl.loginEmail,
        password: ctrl.loginPassword
      }

      GlobalService.post(
        '/login',
        data,
        function success(response) {
          if (response.data.status === 300) {
            ctrl.state = ctrl.states.default
            ctrl.loginError = true
            ctrl.loginErrorMessage = response.data.message
            return false
          }
          if (response.data.status === 500) {
            $('#ServerError')
              .modal({
                blurring: true,
                closable: false
              })
              .modal('show')
            ctrl.authFailed = true
            ctrl.state = ctrl.states.default
            return false
          }
          window.location.reload()
        },
        function error() {
          $('#ServerError')
            .modal({
              blurring: true,
              closable: false
            })
            .modal('show')
          ctrl.authFailed = true
          ctrl.state = ctrl.states.default
          //console.log("error invoked");
        }
      )
    }

    ctrl.emailChanged = function() {
      ctrl.checkingEmail = true
      ctrl.signUpError = false
      GlobalService.checkEmail(
        ctrl.email,
        function(res) {
          res = res.data
          var didYouMean = res.did_you_mean
          var isValid = res.is_valid
          if (!isValid) {
            ctrl.signUpError = true
            ctrl.signUpErrorMessage = COMMONS.valid_email
            ctrl.signUpForm.email.$setDirty()
            ctrl.mailgunPassed = false
            ctrl.didYouMean = didYouMean
          } else {
            ctrl.mailgunPassed = true
            ctrl.didYouMean = null
          }

          ctrl.checkingEmail = false
        },
        function() {
          ctrl.signUpError = false
          ctrl.mailgunPassed = true
          ctrl.checkingEmail = false
        }
      )
    }

    ctrl.getEmailLoader = function() {
      if (ctrl.checkingEmail) {
        return COMMONS.loading
      } else {
        return ''
      }
    }

    ctrl.closeModal = function() {
      $('.ui.modal').hide()
    }

    ctrl.doConfirmEmail = function() {
      if (!ctrl.checkRequiredFields(ctrl.signUpForm)) {
        return
      }

      //check email
      if (!pouchLib.string.isEmail(ctrl.email)) {
        ctrl.signUpError = true
        ctrl.signUpErrorMessage = COMMONS.valid_email
        ctrl.signUpForm.email.$setDirty()
        return false
      } else {
        if (ctrl.mailgunPassed) {
          ctrl.state = ctrl.states.signUp
          var data = {
            email: ctrl.email,
            redirectUrl: ctrl.redirectUrl
          }

          GlobalService.post(
            '/confirmEmail',
            data,
            function success(response) {
              if (response.data === 'Duplicate email') {
                ctrl.state = ctrl.states.default
                ctrl.signUpError = true
                ctrl.signUpErrorMessage =
                  'Email already exist in our system. Please choose a different email address.'
                return false
              } else if (response.data.status === 300) {
                ctrl.state = ctrl.states.default
                ctrl.signUpError = true
                ctrl.signUpErrorMessage = response.data.message

                return false
              } else {
                pouchLib.mixpanel.setUser(response.data)
                window.location.href = response.data.redirectUrl
              }
            },
            function error() {
              ctrl.state = ctrl.states.default
            }
          )
        }
      }
    }

    ctrl.doSignUp = function() {
      if (!ctrl.checkRequiredFields(ctrl.signUpForm)) {
        return
      }

      ctrl.signUpError = false
      //ctrl.passwordError = false;

      //check email
      if (!pouchLib.string.isEmail(ctrl.email)) {
        ctrl.signUpError = true
        ctrl.signUpErrorMessage = COMMONS.valid_email
        ctrl.signUpForm.email.$setDirty()
        return false
      }

      if (!ctrl.passwordError && !ctrl.signUpError) {
        if (ctrl.mailgunPassed) {
          ctrl.state = ctrl.states.signUp
          var data = {
            firstName: ctrl.firstName,
            lastName: ctrl.lastName,
            email: ctrl.email,
            password: ctrl.password,
            retypePassword: ctrl.retypePassword
          }

          GlobalService.post(
            '/signUp',
            data,
            function success(response) {
              if (response.data.status === 300) {
                ctrl.state = ctrl.states.default
                ctrl.signUpError = true
                ctrl.signUpErrorMessage = response.data.message
                return false
              } else {
                pouchLib.mixpanel.setUser(response.data)
                window.location.reload()
              }
            },
            function error() {
              ctrl.state = ctrl.states.default
            }
          )
        }
      }
    }

    ctrl.doCheckPassword = function() {
      if (!ctrl.checkRequiredFields(ctrl.signUpForm)) {
        return
      }

      ctrl.passwordError = false

      if (ctrl.password !== ctrl.retypePassword) {
        ctrl.passwordMessage = COMMONS.password_mismatch
        ctrl.passwordError = true
        return false
      } else if (ctrl.password.length < 8 || ctrl.retypePassword.length < 8) {
        ctrl.passwordMessage = COMMONS.password_tooshort
        ctrl.passwordError = true
        return false
      }
    }

    ctrl.checkRequiredFields = function(form) {
      if (form.$error.required) {
        angular.forEach(form, function(value) {
          if (
            typeof value === 'object' &&
            value.hasOwnProperty('$modelValue')
          ) {
            value.$setDirty()
          }
        })
        return false
      }
      return true
    }

    ctrl.doReset = function() {
      if (
        ctrl.checkRequiredFields(ctrl.passwordResetForm) === false ||
        !ctrl.isEmailValid(ctrl.resetEmail)
      ) {
        return
      }
      ctrl.state = ctrl.states.resetting

      var data = {
        email: ctrl.resetEmail
      }

      GlobalService.post(
        '/reset',
        data,
        function success(response) {
          if (response.data.status === 300) {
            ctrl.resetPasswordFailed = true
            ctrl.state = ctrl.states.default
          } else {
            ctrl.state = ctrl.states.resetSuccess
          }
        },
        function error() {
          ctrl.resetPasswordFailed = true
          ctrl.state = ctrl.states.default
          //console.log("error invoked");
        }
      )
    }

    ctrl.closeReset = function() {
      $('.-resetpassword').modal('hide')
      $timeout(function() {
        $('.ui.modal.-login')
          .modal({
            blurring: true,
            closable: false,
            duration: 200
          })
          .modal('show')
        ctrl.state = ctrl.states.default
      }, 1000)
    }

    ctrl.closeResetFromEmail = function() {
      setTimeout(function() {
        $('.ui.modal.-login')
          .modal({
            blurring: true,
            closable: false,
            duration: 200
          })
          .modal('show')
        ctrl.state = ctrl.states.default
      }, 1000)
    }

    ctrl.checkPasswordMismatch = function(form) {
      if (form.password.$dirty && form.confirmPassword.$dirty) {
        return ctrl.password !== ctrl.retypePassword
      } else {
        return false
      }
    }

    ctrl.resetEmailChanged = function() {
      // if (
      //   ctrl.resetEmail === '' ||
      //   ctrl.resetEmail === null ||
      //   ctrl.resetEmail.trim().length === 0
      // ) {
      //   ctrl.resetPasswordFailed = false
      // }
      ctrl.resetPasswordFailed = false
    }

    ctrl.isEmailValid = function(email) {
      return pouchLib.string.isEmail(email)
    }
  }
])
